import {DefaultButton} from '../Buttons/DefaultButton'
import {getRandomKey} from '../../Helpers/Default'
import {ShowMore375px} from '../Responsible'
import * as React from 'react'
import {Link, NavLink} from 'react-router-dom'

export const LinkButton = (props: any) => {
  return (
    <Link to={props.link}>
      <DefaultButton
        size={'sm'}
        color={props.color}
        className={props.className}
        key={getRandomKey()}
        icon={props.icon}
        tooltip={props.tooltip}
      >
        {props.text}
      </DefaultButton>
    </Link>
  )
}
